
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import { ApiBase, ApiShipments } from "@/core/api";
import { ProductItem } from "@/core/directive/interface/order";
import { getServiceProviderMap } from "@/core/directive/function/shipment";
import { formatDate } from "@/core/directive/function/common";

export default defineComponent({
  name: "transport-management-shipment-detail",
  components: {},
  setup() {
    const { t } = useI18n();

    const { showServerErrorMsg } = mixin();

    const route = useRoute();

    const loading = ref(false);

    const formData = ref({
      id: route.params.id,
      shipment_info: {
        created_at: null,
        id: route.params.id,
        shipment_id: "",
        status: "",
        shipment_type: "",
        fulfillment_stage: "",
        service_provider: "" as string | number,
        pick_up_by: "",
        tracking_no: "",
        departure_delivery_from: "",
        destination: "",
        destination_warehouse_id: "",
        delivery_from_location: {
          name: "",
          street: "",
          zip_code: "",
          city: "",
          country_iso_2: "",
        },
        destination_location: {
          name: "",
          street: "",
          zip_code: "",
          city: "",
          country_iso_2: "",
        },
        file_id: 0,
        file_name: "",
        // EU Shipment
        actual_time_of_departure: null,
        estimated_time_of_departure: null,
        actual_time_of_arrival: null,
        estimated_time_of_arrival: null,
      },
      shipment_items: [] as ProductItem[],
      __show: {
        status: "",
        shipment_type: "",
      },
      // Overseas Shipment
      shipment_invoice_id: 0,
      carrier_information: {
        created_at: null,
        destination_port: 0,
        flight_departure_date: null,
        flight_landing_date: null,
        flight_number: "",
        custom_declaration_by: "",
        export_custom_declaration: "",
        import_custom_declaration: "",
        id: "",
        shipment_id: "",
      },
      packing_specification: {
        actual_gross_weight: "",
        chargeable_weight: "",
        created_at: null,
        dimension: "",
        id: 1,
        packing_container: 0,
        quantity: 0,
        shipment_id: "",
        total_amount: "",
        total_gross_weight: "",
        total_volume: "",
        total_volume_weight: "",
        shipment_fee_amount: "",
        shipment_fee_currency: "",

        additional_fee_amount: "",
        additional_fee_currency: "",
      },
      // CN Shipment
    });

    const options = ref({
      fulfillment_stage: new Map([]),
    });

    const getShowInfo = async () => {
      // loading.value = true;
      const { data } = await ApiShipments.showShipment({ id: route.params.id });
      // loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getShowInfo(), getTaggingData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["fulfillment_stage"],
      });
      if (data.code == 0) {
        data.data.fulfillment_stage.items.map((item) => {
          options.value.fulfillment_stage.set(Number(item.value), item.label);
        });
      }
    };

    const getServiceProvider = computed(() => {
      return (value) => {
        return getServiceProviderMap(value);
      };
    });

    const getData = () => {
      getFromInfo();
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      getFromInfo();
    });

    return {
      t,
      formatDate,
      getServiceProvider,
      getData,
      loading,
      formData,
      options,
    };
  },
});
