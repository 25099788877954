import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body pt-9 pb-0" }
const _hoisted_3 = { class: "d-flex flex-column flex-wrap flex-sm-nowrap p-0" }
const _hoisted_4 = { class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex align-items-center text-gray-400 me-5"
}
const _hoisted_6 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_7 = {
  key: 1,
  class: "d-flex align-items-center text-gray-400 me-5"
}
const _hoisted_8 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_9 = {
  key: 2,
  class: "d-flex align-items-center text-gray-400 me-5"
}
const _hoisted_10 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_11 = {
  key: 3,
  class: "d-flex align-items-center text-gray-400 me-5"
}
const _hoisted_12 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_13 = {
  key: 4,
  class: "d-flex align-items-center text-gray-400 me-5"
}
const _hoisted_14 = { class: "d-flex overflow-auto h-55px" }
const _hoisted_15 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap" }
const _hoisted_16 = { class: "nav-item" }
const _hoisted_17 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.formData.shipment_info.tracking_no
              ? _ctx.formData.shipment_info.tracking_no + " / "
              : "") + " " + _toDisplayString(_ctx.formData.shipment_info.shipment_id
              ? "#" + _ctx.formData.shipment_info.shipment_id + " / "
              : "") + " " + _toDisplayString(_ctx.formData.__show.status), 1),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.formData.__show.shipment_type)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen008.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.formData.__show.shipment_type), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.formData.shipment_info.fulfillment_stage)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen059.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.options.fulfillment_stage.get(
                _ctx.formData.shipment_info.fulfillment_stage
              )), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.formData.shipment_info.actual_time_of_departure)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen013.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.formData.shipment_info.actual_time_of_departure)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.formData.shipment_info.actual_time_of_arrival)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen013.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.formData.shipment_info.actual_time_of_arrival)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.formData.shipment_info.service_provider)
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                  (_ctx.getServiceProvider(_ctx.formData.shipment_info.service_provider))
                    ? (_openBlock(), _createBlock(_component_el_image, {
                        key: 0,
                        class: "d-flex w-25px h-25px",
                        lazy: "",
                        fit: "contain",
                        src: _ctx.getServiceProvider(_ctx.formData.shipment_info.service_provider)
                      }, null, 8, ["src"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ])), [
          [_directive_loading, _ctx.loading]
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("ul", _hoisted_15, [
            _createElementVNode("li", _hoisted_16, [
              _createVNode(_component_router_link, {
                to: 
                '/transport-management/shipments/' +
                _ctx.$route.params.id +
                '/' +
                _ctx.$route.params.shipment_id +
                '/edit'
              ,
                replace: "",
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("common.information")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", _hoisted_17, [
              _createVNode(_component_router_link, {
                to: 
                '/transport-management/shipments/' +
                _ctx.$route.params.id +
                '/' +
                _ctx.$route.params.shipment_id +
                '/logs'
              ,
                replace: "",
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("shipments.logs")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_router_view, { onGetFormData: _ctx.getData }, null, 8, ["onGetFormData"])
  ], 64))
}